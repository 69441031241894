import { render, staticRenderFns } from "./letter-logo.vue?vue&type=template&id=25fc4bc6&scoped=true&"
import script from "./letter-logo.vue?vue&type=script&lang=ts&setup=true&"
export * from "./letter-logo.vue?vue&type=script&lang=ts&setup=true&"
import style0 from "./letter-logo.vue?vue&type=style&index=0&id=25fc4bc6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25fc4bc6",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/daniel/Desktop/business/EFM/02 RCP/code/rcp-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('25fc4bc6')) {
      api.createRecord('25fc4bc6', component.options)
    } else {
      api.reload('25fc4bc6', component.options)
    }
    module.hot.accept("./letter-logo.vue?vue&type=template&id=25fc4bc6&scoped=true&", function () {
      api.rerender('25fc4bc6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/pages/report/components/letter-logo.vue"
export default component.exports