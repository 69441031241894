var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "PrintPage",
    {
      attrs: { maxpages: _vm.maxpages, pagenum: _vm.pagenum },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function () {
            return [
              _c("ReportHeader", {
                attrs: {
                  logo: _vm.logo,
                  showEdit: _vm.showEdit,
                  textFields: _vm.textFields,
                },
              }),
            ]
          },
          proxy: true,
        },
        {
          key: "footer",
          fn: function () {
            return [_vm.hasFooter ? _c("EfmFooter") : _vm._e()]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _c("main", [
        _c(
          "div",
          {
            staticClass:
              "d-flex justify-content-between page-title-wrapper mb-2",
          },
          [
            _c("h2", { staticClass: "report-title" }, [_vm._v("Risikoprofil")]),
            _vm._v(" "),
            _c("h2", { staticClass: "report-title" }, [
              _vm._v("\n        " + _vm._s(_vm.reportDate) + "\n      "),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "text-field-wrapper" },
          [
            _c("p", {
              staticStyle: { "white-space": "pre-wrap" },
              domProps: { innerHTML: _vm._s(_vm.introductionGeneral?.value) },
            }),
            _vm._v(" "),
            _c("TextFieldEditButton", {
              attrs: {
                "show-edit": _vm.showEdit,
                "text-field-to-edit": _vm.introductionGeneral,
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "my-3 d-flex align-items-center" }, [
          _c("img", {
            staticClass: "mx-auto",
            attrs: {
              alt: "Risikoprofil Farblegende",
              height: "auto",
              src: "content/images/cdp_color_legend_v4.svg",
              width: "70%",
            },
          }),
        ]),
        _vm._v(" "),
        _c("hr"),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "text-field-wrapper" },
          [
            _c("p", {
              domProps: { innerHTML: _vm._s(_vm.finalNoteTextField?.value) },
            }),
            _vm._v(" "),
            _c("TextFieldEditButton", {
              attrs: {
                "show-edit": _vm.showEdit,
                "text-field-to-edit": _vm.finalNoteTextField,
              },
            }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }