var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm.showLogo ? _c(_setup.LetterLogo) : _vm._e(),
      _vm._v(" "),
      _vm.showHeading
        ? _c("div", { staticClass: "letter-header-wrapper" }, [
            _c(
              "div",
              { staticClass: "text-field-wrapper" },
              [
                _c("p", {
                  staticClass: "letter-header",
                  staticStyle: { "white-space": "pre-wrap" },
                  domProps: { innerHTML: _vm._s(_setup.letterHeader?.value) },
                }),
                _vm._v(" "),
                _c(_setup.TextFieldEditButton, {
                  attrs: {
                    "show-edit": _vm.showEdit,
                    "text-field-to-edit": _setup.letterHeader,
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "text-field-wrapper" },
              [
                _c("p", {
                  staticClass: "letter-editor",
                  staticStyle: { "white-space": "pre-wrap" },
                  domProps: { innerHTML: _vm._s(_setup.editor?.value) },
                }),
                _vm._v(" "),
                _c(_setup.TextFieldEditButton, {
                  attrs: {
                    "show-edit": _vm.showEdit,
                    "text-field-to-edit": _setup.editor,
                  },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }